<template>
  <BackofficeBase :loader_prop="loader_prop">
    <div v-if="! loader_prop">
      Thanks for your order!
    </div>
  </BackofficeBase>
</template>

<style>
.img_pre {
  max-width: 50em;
  margin-top: 1em;
}
</style>

<script>
import BackofficeBase from "./BackofficeBase";

export default {
  name: 'BackofficePaymeOrderTy',
  components: {
    BackofficeBase
  },
  data() {
    return {
      loader_prop : false
    }
  },
  async mounted() {

  },
  methods : {

  }
}
</script>
